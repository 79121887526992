<template>
  <div class="row sport-complex-wrapper">
    <div class="col-md-6 col-12  sport-complex-text-wrapper">
      <p class="sport-complex-saving m-0">
        Annual Savings: <span>$115,000</span>
      </p>
      <h3 class="sport-complex-title m-0">Sports Complex <br /> in Gravesend</h3>
      <p class="sport-complex-description m-0">MatchPoint NYC is your sports and fitness
        destination for the entire family. Spread
        across 120,000 sq. ft., premier health and
        fitness complex features a cutting edge
        tennis facility that includes 9 indoor
        tennis courts.</p>
      <HelpButton text="Check if Your Project is Eligible" class="sport-complex-button" width="288px"></HelpButton>
    </div>
    <div class="col-md-6 col-12 ">
      <div class="sport-complex-img "></div>
    </div>
  </div>
</template>

<script>
import HelpButton from "@/components/HelpButton";
export default {
  name: "SportsCompex",
  components: {
    HelpButton
  }
}
</script>

<style scoped>
.sport-complex-wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
}
.sport-complex-img{
  height: 480px;
  width: 480px;
  background: url("../assets/assets_icap_web/photo_5.png") no-repeat;
  background-size: cover;
  margin-left: 67px;
}

.sport-complex-saving{
  width: 202px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
}
.sport-complex-saving span{
  color: #4D59EB;
  font-weight: 600;
  display: inline-block;
  position: relative;
  margin-left: 10px;
}
.sport-complex-saving span:before{
  content: "";
  position: absolute;
  left: -12px;
  top: -11px;
  height: 45px;
  width: 99.83px;
  background: url("../assets/assets_icap_web/line_4.png") no-repeat;
  background-size: cover;
}
.sport-complex-text-wrapper {
  padding-top: 44px;
  padding-left: 222px;
}
.sport-complex-title {
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 38px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 48px;
  margin-top: 36px !important;
}
.sport-complex-description {
  color: #0A1250;

  width: 386px;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 20px !important;
}
.sport-complex-button {
  margin-top: 44px;
}


@media screen and (min-width: 768px) and (max-width: 1439px) {
  .sport-complex-wrapper {
    padding-top: 65px;
    max-width: 640px;
    margin: 0 auto;
  }
  .sport-complex-img{
    height: 280px;
    width: 280px;
    margin: 47px 0 0 23px !important;
  }
  .sport-complex-text-wrapper {
    padding-top: 0;
    padding-left: 0px;
  }
  .sport-complex-title {
    height: 72px;
    width: 208px;
    font-size: 28px;
    line-height: 36px;
    margin-top: 32px !important;
  }
  .sport-complex-description {
    height: 150px;
    width: 297px;
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: 16px !important;
  }

  .sport-complex-button {
    margin-top: 32px;
  }
}


@media screen and (min-width: 375px) and (max-width: 767px) {
  .sport-complex-wrapper {
    max-width: 350px;
    margin: 0 auto;
    padding-bottom: 48px;
    padding-top: 0px;
  }
  .sport-complex-img{
    height: 180px;
    width: 343px;
    margin: 0 -15px;
  }
  .sport-complex-text-wrapper {
    padding-top: 32px;
    padding-left: 0px; /* 113px - 15px (col-6 padding) - 15px (col-6 padding neighbour ) */
    padding-right: 0;
  }
  .sport-complex-title {
    height: auto;
    font-size: 28px;
    line-height: 36px;
    margin-top: 24px !important;
  }
  .sport-complex-description {
    width: 275px;
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: 16px !important;
  }
  .sport-complex-button {
    margin-top: 24px;
    margin-bottom: 48px;
  }
}

@media screen  and (max-width: 374px) {
  .sport-complex-wrapper {
    max-width: 350px;
    margin: 0 -15px;
    padding-bottom: 40px;
    padding-top: 0px;
  }
  .sport-complex-img{
    height: 180px;
    width: 288px;
    margin: 0;
    background-position: center;
  }
  .sport-complex-text-wrapper {
    padding-top: 32px;
    padding-left: 15px; /* 113px - 15px (col-6 padding) - 15px (col-6 padding neighbour ) */
  }
  .sport-complex-title {
    height: auto;
    font-size: 28px;
    line-height: 36px;
    margin-top: 24px !important;
  }
  .sport-complex-description {
    width: 275px;
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: 16px !important;
  }
  .sport-complex-button {
    margin-top: 24px;
    margin-bottom: 40px;
  }
}
</style>
