<template>
    <div class="wrap container-fluid">
      <div class="row h-100">
        <div class="col-lg-4 offset-lg-2 col-md-5 p-0">
          <div class="d-flex justify-content-center align-items-center head-pos">
            <div>
              <p class="header">
                Don't miss
              </p>
              <p class="header">
                an opportunity
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-7 p-0">
          <div class="d-flex align-items-center descr-pos">
            <p class="tetx-descr">
              to qualify for one of the last remaining commercial tax
              abatement opportunities for new development,
              modernization or renovation projects.
            </p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
name: "DontMiss"
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
.head-pos{
  height: 100%;
}
.descr-pos{
  height: 100%;
}
.header{
  width: 265px;
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 38px;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 0;
}
.tetx-descr{
  width: 511px;
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 0;
}
.wrap{
  height: 320px;
  background-color: #1F286B;
  //display: flex;
  //justify-content: center;
  //align-items: center;
}
@media screen and (min-width: 768px) and (max-width: 1439px) {
  .wrap{
    height: 192px;
  }
  .header{
    width: 195px;
    font-size: 28px;
    line-height: 36px;
  }
  .tetx-descr{
    width: 362px;
    font-size: 15px;
    line-height: 25px;
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .wrap{
    height: 284px;
  }
  .header{
    width: 195px;
    font-size: 28px;
    line-height: 36px;
    text-align: center !important;
  }
  .tetx-descr{
    width: 291px;
    font-size: 15px;
    line-height: 25px;
    text-align: center !important;
  }
  .descr-pos{
    justify-content: center;
    height: 96px;
    margin-top: -36px;
  }
  .head-pos{
    height: 76px;
    margin-top: 46px;
  }

}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .wrap{
    height: 284px;
  }
  .header{
    width: 195px;
    font-size: 28px;
    line-height: 36px;
    text-align: center !important;
  }
  .tetx-descr{
    width: 291px;
    font-size: 15px;
    line-height: 25px;
    text-align: center !important;
  }
  .descr-pos{
    justify-content: center;
    height: 96px;
    margin-top: -36px;
  }
  .head-pos{
    height: 76px;
    margin-top: 46px;
  }
}
</style>
