<template>
  <div class="row private-school-wrapper flex-md-row-reverse">
    <div class="col-md-6 col-12 private-school-text-wrapper">
      <p class="private-school-saving m-0">
        Annual Savings: <span>$1,100,000</span>
      </p>
      <h3 class="private-school-title m-0">Private School <br /> in Red Hook</h3>
      <p class="private-school-description m-0">BASIS Independent is transforming the nation’s
        college preparatory programs one school at a time.</p>
      <HelpButton text="Check if Your Project is Eligible" class="private-school-button" width="288px"></HelpButton>
    </div>
    <div class="col-md-6 col-12 text-right float-right img-wrap">
      <div class="private-school-img"></div>
    </div>
  </div>
</template>

<script>
import HelpButton from "@/components/HelpButton";
export default {
  name: "PrivateSchool",
  components: {
    HelpButton
  }
}
</script>
<style>

.row {
  /*margin-right: auto !important;*/
  /*margin-left: auto !important;*/
}
</style>
<style scoped>
.private-school-wrapper {
  padding-bottom: 80px;
}
.private-school-img{
  height: 480px;
  width: 480px;
  background: url("../assets/assets_icap_web/photo_2.png") no-repeat;
  background-size: cover;
  margin-right: 65px;
  display: inline-block;
}

.private-school-saving{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
}
.private-school-saving span{
  color: #4D59EB;
  font-weight: 600;
  display: inline-block;
  position: relative;
  margin-left: 12px;
}
.private-school-saving span:before{
  content: "";
  position: absolute;
  left: -15px;
  top: -11px;
  width: 109.81px;
  height: 44px;
  background: url("../assets/assets_icap_web/line_1.png") no-repeat;
  background-size: cover;
}
.private-school-text-wrapper {
  padding-top: 61px;
  padding-left: 83px; /* 113px - 15px (col-6 padding) - 15px (col-6 padding neighbour ) */
}
.private-school-title {
  height: 96px;
  width: 251px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 38px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 48px;
  margin-top: 36px !important;
}
.private-school-description {
  height: 50px;
  width: 358px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 20px !important;
}
.private-school-button {
  margin-top: 44px;
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  .private-school-wrapper {
    max-width: 640px;
    margin: 0 auto;
  }
  .private-school-img{
    height: 280px;
    width: 280px;
    margin: 10px 0 0 !important;
  }
  .private-school-text-wrapper {
    padding-top: 0;
    padding-left: 15px;
  }
  .private-school-title {
    height: 72px;
    width: 185px;
    font-size: 28px;
    line-height: 36px;
    margin-top: 32px !important;
  }
  .private-school-description {
    height: 75px;
    width: 275px;
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: 16px !important;
  }
  .private-school-button {
    margin-top: 32px;
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .private-school-wrapper {
    max-width: 350px;
    margin: 0 auto;
    padding-bottom: 48px;
  }
  .private-school-img{
    height: 180px;
    width: 343px;
    margin-right: 0;
    background-position: center;
  }
  .img-wrap{
    padding: 0;
  }
  .private-school-text-wrapper {
    padding-top: 32px;
    padding-left: 0px; /* 113px - 15px (col-6 padding)  */
    padding-right: 0;
  }
  .private-school-title {
    height: auto;
    font-size: 28px;
    line-height: 36px;
    margin-top: 24px !important;
  }
  .private-school-description {
    height: 75px;
    width: 275px;
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: 16px !important;
  }
  .private-school-button {
    margin-top: 24px;
    margin-bottom: 48px;
  }
}
@media screen and (min-width: 320px)  and (max-width: 374px) {
  .private-school-wrapper {
    max-width: auto;
    margin: 0 auto;
    padding-bottom: 40px;
  }
  .private-school-img{
    height: 180px;
    width: 288px;
    margin-right: auto;
    background-position: center;
  }
  .img-wrap{
    padding: 0;
    text-align: left;
  }
  .private-school-text-wrapper {
    padding-top: 32px;
    padding-left: 0px; /* 113px - 15px (col-6 padding)  */
    padding-right: 0px; /* 113px - 15px (col-6 padding)  */
  }
  .private-school-title {
    height: auto;
    font-size: 28px;
    line-height: 36px;
    margin-top: 24px !important;
  }
  .private-school-description {
    height: 75px;
    width: 275px;
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: 16px !important;
  }
  .private-school-button {
    margin-top: 24px;
    width: 100%;
    margin-bottom: 40px;
  }
}
</style>
