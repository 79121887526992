<template>
  <div class="position-relative container-fluid ">
    <div class="row">
      <div class="col-lg-6 col-md-8 p-0 rectangle hero-page-wrapper d-flex justify-content-center align-items-center" >
        <div class="text-center">
          <div class="texts">
            <p>ICAP Tax Abatement</p>
            <p>+</p>
            <p>MGNY Consulting</p>
            <p>=</p>
            <p class="blue-text">Long-Term Tax Savings</p>
          </div>
          <HelpButton :text="'Check if Your Project is Eligible'"></HelpButton>
        </div>
      </div>
      <div class="col-lg-6 col-md-4 p-0" >
        <div class="hero-img"></div>
      </div>
    </div>
  </div>
</template>

<script>
import HelpButton from '@/components/HelpButton'
export default {
    name: "HeroPage",
  components:{
    HelpButton
  },

    methods: {
      openSite(){
        window.open('https://mgnyconsulting.com')
      }

    }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
.hero-page-wrapper{
  height: 640px;
}
.blue-text{
  color: $font-blue
}
.hero-img{
  height: 640px;
  background: url("../assets/assets_icap_web/photo_1.png") no-repeat center;
  background-size: cover;
}
.rectangle{
 background-color:  $blue-light
}
.texts{
  color: $font-black;
  font-family: "Open Sans";
  font-size: 48px;
  font-weight: 300;
  letter-spacing: -0.92px;
  line-height: 52px;
  text-align: center;
  margin-bottom: 40px;
  &>p{
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  .hero-page-wrapper{
    height: 470px;
  }
  .texts{
    font-size: 36px;
    letter-spacing: -0.69px;
    line-height: 48px;
    margin-bottom: 32px;
  }
  .hero-img{
    height: 470px;
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .hero-page-wrapper{
    height: 520px;
  }
  .texts{
    font-size: 32px;
    letter-spacing: -0.69px;
    line-height: 40px;
    margin-bottom: 32px;
  }
  .hero-img{
    height: 288px;
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .hero-page-wrapper{
    height: 400px;
  }
  .texts{
    font-size: 28px;
    font-weight: 300;
    letter-spacing: -0.54px;
    line-height: 40px;
    margin-bottom: 28px;
  }
  .hero-img{
    height: 220px;
  }
}

</style>
