<template>
    <div class="header-wrap d-flex justify-content-between align-items-center">
      <div class="">
        <img src="../assets/logo.svg" @click="openSite()" class="logo" alt="">
      </div>
      <div class="help-block">
        <div class="help">
          <p class="mb-0 ">
            Need Help?
          </p>
        </div>
        <div class="d-inline-block">

          <p class="mb-0 pill">
            <a class="call-button" href="tel:718-522-1111">Call Us: 718-522-1111</a>
          </p>
        </div>
      </div>
    </div>
</template>

<script>
export default {
name: "Header",
  methods: {
    openSite(){
      window.open('https://mgnyconsulting.com')
    }

  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.help-block{
  color: $font-black;

}
.call-button{
  height: 20px;
  width: 153px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}
.help{
  margin-right: 16px;
  display: inline-block;
}
.header-wrap{
  height: 100px;
  padding: 0 120px
}

.pill{
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 44px;
  width: 193px;
  border: 1px solid #E6E8F0;
  border-radius: 24px;
  background-color: #FFFFFF;
}
@media screen and (min-width: 768px) and (max-width: 1439px) {
  .header-wrap{
    height: 80px;
    padding: 0 65px
  }
  .logo{
    width: 48px;
    height: 43px;
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .header-wrap{
    height: 80px;
    padding: 0 16px
  }
  .help{
    display: none;
  }
  .logo{
    width: 48px;
    height: 43px;
  }
  .pill{
    height: 40px;
    width: 193px;
  }
  .call-button{
    height: 20px;
    width: 153px;
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .header-wrap{
    height: 80px;
    padding: 0 16px
  }
  .logo{
    width: 48px;
    height: 43px;
  }
  .pill{
    height: 40px;
    width: 193px;
  }
  .help{
    display: none;
  }
}

</style>