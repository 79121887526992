<template>
  <b-modal id="sign-up-modal"
           body-bg-variant="coool"
           header-bg-variant="coool"
           header-class="header-class"
           @hidden="resetModal()"
           hide-footer
           size="lg"
           no-close-on-backdrop
           body-class="modal-class">
    <div class="close-btn" @click="$bvModal.hide('sign-up-modal')">
      <img src="../assets/icn_close.svg" class="cross" alt="X">
    </div>
    <Preloader v-if="is_loading"></Preloader>
    <div v-if="sent_success" class="sent-pad">
      <div class="d-block text-center title-sent">
        <p class="title-bad">Thank you for your interest in ICAP
          tax abatement, we’ll get back to you shortly.</p>
      </div>
      <button @click="$bvModal.hide('sign-up-modal')"  class="help-btn  w-100" >Done!</button>
    </div>
    <div v-else-if="sent_error" class="sent-pad">
<!--      <img src="../assets/error_outline.png" class="d-block mx-auto" alt="">-->
      <div class="d-block text-center p-3">
        <p class="title">Something went wrong</p>
        <div class="descr form-sent">
          <p class="mb-0">We are working on this problem now. </p>
          <p class="mb-0">Please try again or contact us: <b>718-522-1111</b></p>
        </div>
        <button @click="$bvModal.hide('sign-up-modal')"  class="help-btn  w-100" >Try again</button>
      </div>
    </div>
    <div v-else class="form-wrap">
      <div class="d-flex justify-content-center">
        <div v-if="result === true">
          <p class="title text-center">
            Your Project May Receive
            a 15 Year Tax Abatement</p>
          <p class="help-text">
            Please provide your contact information below, and we will
            reach out to you within 24 hours to discuss your project!
          </p>
        </div>
        <div v-else>
          <p class="title-bad text-center">
            It appears that your project is ineligible, but we
            would love to do more research to confirm that!</p>
          <p class="help-text-bad">
            If your construction involves multiple tax lots,
            enter any one lot.
          </p>
        </div>
      </div>
      <div class="wrap-inputs">
        <div class="row radios-block">
          <div class="col-md-6 col-12 mb-32px">
            <b-form-input
                id="name"
                class="cust-inp"
                :class="name_state === false ? 'err-state' : ''"
                v-model="name"
                :state="name_state"
                placeholder="First name"
                trim
                type="text"
            ></b-form-input>
            <div v-if="name_state  === false" >
              <p v-if="!name || name.length === 0"  class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                First name is incorrect
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-32px">
            <b-form-input
                id="lname"
                :class="last_name_state === false ? 'err-state' : ''"
                class="cust-inp"
                v-model="last_name"
                :state="last_name_state"
                placeholder="Last name"
                trim
                type="text"
            ></b-form-input>
            <div v-if="last_name_state  === false" >
              <p v-if="!last_name || last_name.length === 0"  class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                Last name is incorrect
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-32px">
            <b-form-input
                id="mail"
                :class="mail_state === false ? 'err-state' : ''"
                class="cust-inp"
                v-model="mail"
                :state="mail_state"
                placeholder="Email"
                type="email"
                trim
            ></b-form-input>
            <div v-if="mail_state  === false">
              <p v-if="!mail || mail.length === 0"  class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                Email is incorrect
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-32px">
            <TheMask  class="cust-inp form-control"
                      v-model="phone"
                      :class="phone_state ===  false ? 'err-state' : ''"
                      placeholder="Phone Number"
                      mask="(###) ###-####">
            </TheMask>
            <div v-if="phone_state  === false">
              <p v-if="!phone || phone.length === 0"  class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                Phone number is incorrect
              </p>
            </div>
          </div>
          <div class="col-12" >
            <div v-if="sent_success || sent_error" class="lic-less d-flex justify-content-center hand">
              <button @click="$bvModal.hide('sign-up-modal')"  class="help-btn  w-100" >Close</button>
            </div>
            <div v-else class="lic-less d-flex justify-content-center">
              <button @click="signUp" :class="disabled_send ? 'not-allowed' : 'hand'" class="help-btn  w-100" >Submit</button>
            </div>
            <div class="back-container" @click="goBack">
              <p class="back-btn hand mb-0" >Back</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {TheMask} from 'vue-the-mask'
import Preloader from '../components/Preloader.vue'
import axios from 'axios'
import sbjs from 'sourcebuster';
import Vue from "vue";

Vue.use(sbjs)
sbjs.init();
export default {
name: "SignUpModal",
  components: {
    TheMask,
    Preloader
  },

  data: () => ({
    name_state: null,
    last_name_state: null,
    mail_state: null,
    phone_state: null,
    income_state: null,
    name: null,
    last_name: null,
    mail: null,
    phone: null,
    sent_success : false,
    sent_error : false,
    is_loading : false,
    disabled_send : false,
    ConstructionCost: null,
    ConstructionType: null,
    BBL: null,
    result: null,
  }),
  watch:{
    name(){
      this.name_state = null
    },
    last_name(){
      this.last_name_state = null
    },
    mail(){
      this.mail_state = null
    },
    phone(){
      this.phone_state = null
    },
  },
  methods:{
    startModal(paramz){
      console.log(paramz);
      this.ConstructionCost = paramz.ConstructionCost;
      this.ConstructionType= paramz.ConstructionType;
      this.BBL = paramz.BBL;
      this.result = paramz.result;

      this.$bvModal.show('sign-up-modal')
    },
    resetModal(){
      this.sent_success = false;
      this.is_loading = false;
      this.sent_error = false;
      this.name = null;
      this.last_name = null;
      this.mail = null;
      this.phone = null;
      this.name_state = null;
      this.last_name_state =  null;
      this.mail_state = null;
      this.phone_state = null;

      this.ConstructionCost= null;
      this.ConstructionType= null;
      this.BBL= null;
      this.result= null;

    },
    goBack(){
      this.$bvModal.hide('sign-up-modal')
      this.$bvModal.show('calc-modal')
    },
    async signUp(){
      if(this.validate() && !this.disabled_send){
        this.makeDisabled()
        this.is_loading = true;
        await axios.post('https://mgny.force.com/sales/services/apexrest/lead/add',
            {
              'ConstructionCost' : this.ConstructionCost,
              'ConstructionType' : this.ConstructionType,
              'BBL' : this.BBL,
              'FirstName': this.name,
              'LastName': this.last_name,
              'Email': this.mail,
              'Phone': this.phone,
              'FirstSource' : sbjs.get.first.src + '-' +  sbjs.get.first.mdm + '-' + sbjs.get.first.cmp + '-' + sbjs.get.first.trm,
              'LastSource' : sbjs.get.current.src + '-' +  sbjs.get.current.mdm + '-' + sbjs.get.current.cmp + '-' + sbjs.get.current.trm,

            })
            .then(()=>{
              this.sent_success = true
              this.is_loading = false;
            }).catch((error)=>{
              this.sent_error = true;
              this.is_loading = false;
              console.log('Error' + error)
            })
      }
    },
    makeDisabled(){
      this.disabled_send = true;
      setTimeout(() => this.disabled_send = false, 10000)
    },
    validate(){
      this.name_state = null;
      this.last_name_state = null;
      this.mail_state = null;
      this.phone_state = null;
      let is_valid = true
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if(this.name === null || this.name.length < 2){
        this.name_state = false;
        is_valid = false
      }
      if(this.last_name === null || this.last_name.length < 2){
        this.last_name_state = false;
        is_valid = false
      }
      if(this.mail === null || this.mail.length < 5 || !(re.test(String(this.mail).toLowerCase())) ){
        this.mail_state = false;
        is_valid = false
      }
      // if(this.phone && this.phone.length !== 10){
      if(this.phone === null || this.phone.length !== 10){
        this.phone_state = false;
        is_valid = false
      }

    return is_valid

    }
  }
}
</script>
<style>

.wrap-inputs{
  width: 100% !important;
  margin-top: 32px;
}
.not-allowed{
  cursor: not-allowed;
}
.modal-header {
  border-bottom: none!important;
}
.bg-coool{
  background: #FFFFFF;
}
.modal-content {
  border-radius: 10px 10px 10px 10px!important;
}
.modal-class{
  border-radius: 10px!important;
}
.header-class{
  border-radius: 10px!important;
}
.modal-footer > * {
  margin: 0!important;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  color: #FF2929;
}
.form-control {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
}
.modal-backdrop{
  transition: 0.8s;
  opacity: 0.88!important;
  background-color: rgba(1,2,15,1)!important
}
#sign-up-modal___BV_modal_header_{
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .close-btn{
    display: none;
  }
  #sign-up-modal___BV_modal_header_{
    display: block;
  }
  .modal-dialog {
    max-width: 640px!important;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  close-btn{
    display: none;
  }
  #sign-up-modal___BV_modal_header_{
    display: block;
  }
}
</style>


<style lang="scss" scoped>
.title-sent{
  margin-bottom: 80px;
}
.back-container{
  padding: 20px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back-btn{
  height: 20px;
  width: 35px;
  color: #4D59EB;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;

}
.close-btn{
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #4b4d56;
  box-shadow: 0 2px 4px 0 rgba(26, 41, 60, 0.15);
  position: absolute;
  top: 0;
  right: -300px;
  cursor: pointer;
  z-index: 999;
  padding: 8px 13px;
}
.cross{
  height: 15px;
  width: 15px;
  color: #FFFFFF;
}
.sent-pad{
  padding: 94px 34px 32px;
}
.err-state-text{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
}
.err-state{
  background-color: #FFE9E8!important;
  border: 1px solid #FF7564!important;
  &::placeholder{
    color: #FF7564!important;
    font-family: "Open Sans";
  }
}
.err-state.long{
  width: 350px;
  right: 0px;
}
.err-state::after{
  content: '';
  position: absolute;
  left: 49%;
  top: 100%;
  border-left: 4px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f60606;
}
::placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  color: #C6C6C6;
}
.mb-32px{
  margin-bottom: 32px;
}
.mb-40px{
  margin-bottom: 40px;
}
.thank{
  width: 109.6px;
  margin-bottom: 50px;
}
.form-wrap{
  padding: 16px;
}
.title{
  width: 288px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  margin: 0 auto 12px;
}
.title-bad{
  width: 536px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  margin: 0 auto 12px;
}
.help-text-bad{
  width: 321px;
  color: #7F83A7;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin: auto;
}
.help-text{
  width: 416px;
  color: #7F83A7;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin: auto;
}
.descr{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-bottom: 87px;
}
.text-blue{
  color: #2692F5;
}
.description-wrap{
  padding:30px
}
.cust-inp{
  height: 48px;
  border-radius: 6px;
  border-color: transparent;
  background-color: #F5F5F7;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  color: #0A1250;
  &::placeholder{
    color: #7F83A7;
    font-family: "Open Sans";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
   }
  &:active{
    box-sizing: border-box;
    height: 48px;
    border: 1px solid #4D59EB;
    border-radius: 6px;
  }
  &:focus{
    box-shadow: none;
    border: 1px solid #4D59EB;
  }
}
.hand{
  cursor: pointer;
}

@media screen and (min-width: 768px) and (max-width: 1223px) {
  .wrap-inputs{
    padding: 0;
    width: 100% !important;
    margin-top: 40px;
  }
  .title {
    margin: -19px 0 12px;
  }
  .title-bad {
    margin: -29px 0 12px;
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .form-wrap[data-v-c3bdf4ea] {
    padding: 0;
  }
  .title{
    margin : -30px 0 12px;
    font-size: 24px;
    line-height: 33px;
  }
  .wrap-inputs{
    padding: 0;
    width: 100% !important;
    margin-top: 25px;
  }
  .mb-32px{
    margin-bottom: 28px;
  }
  .sent-pad{
    padding: 0 0px 38px;
  }
  .descr{
    margin-bottom: 60px;
    font-size: 24px;
    line-height: 33px;
  }
  .help-text{
    width:288px
  }
  .title-bad{
    width:338px
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .form-wrap[data-v-c3bdf4ea] {
    padding: 0;
  }
  .help-text{
    width:288px
  }
  .title-bad{
    width:295px
  }
  .help-text-bad{
    width:288px
  }
  .title{
    margin : -30px 0 -5px;
    font-size: 22px;
    line-height: 37px;
  }
  .wrap-inputs{
    padding: 0 1px 32px;
    width: 100% !important;
    margin-top: 15px;
  }
  .mb-32px{
    margin-bottom: 24px;
  }
  .sent-pad{
    padding: 0 0px 38px;
  }
  .descr{
    margin-bottom: 50px;
  }
}

</style>