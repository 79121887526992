<template>
  <div class="wrapper">
    <div class="full">©2021 MGNY Consulting. All rights reserved</div>
  </div>
</template>

<script>
export default {
name: "Footer",
  methods:{
    openSite(){
      window.open('https://mgnyconsulting.com')
    }
  }
}
</script>

<style scoped>
.full{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 10px;
  letter-spacing: 0;
  text-align: center;
  line-height: 56px;
  background-color: rgba(247, 231, 217, 0.45);
  height: 56px;
}
.wrapper{
  height: 56px;
  background-color: rgba(255, 243, 222, 0.4);
}
</style>
