<template>
<div class="office-building-wrapper">
  <p class="office-building-saving m-0">
    Annual Savings: <span>$78,000</span>
  </p>
  <h3 class="office-building-title m-0">Office Building<br />
    in Sheepshead Bay</h3>
  <p class="office-building-description m-0">The Bay Tower has raised the standard for commercial
    office space in South Brooklyn and now houses diverse
    businesses serving the local community.</p>

  <HelpButton text="Check if Your Project is Eligible" class="d-md-none office-building-button" width="285px"></HelpButton>
  <div class="office-building-img"></div>
</div>
</template>

<script>
import HelpButton from "@/components/HelpButton";
export default {
  name: "OfficeBuilding",
  components: {
    HelpButton
  }
}
</script>

<style scoped>
.office-building-wrapper {
  padding-top: 40px;
  padding-bottom: 80px;
  padding-left: 120px;
}
.office-building-img{
  height: 280px;
  width: 480px;
  background: url("../assets/assets_icap_web/photo_3.png") no-repeat;
  background-size: cover;
  margin-top: 80px;
}

.office-building-saving{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
}
.office-building-saving span{
  color: #4D59EB;
  font-weight: 600;
  display: inline-block;
  position: relative;
  margin-left: 10px;
}
.office-building-saving span:before{
  content: "";
  position: absolute;
  left: -12px;
  top: 24px;
  height: 12px;
  width: 93.38px;
  background: url("../assets/assets_icap_web/line_2.png") no-repeat;
  background-size: cover;
}
.office-building-title {
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 38px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 48px;
  margin-top: 40px !important;
}
.office-building-description {
  color: #0A1250;
  width: 386px;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 20px !important;
}
.office-building-button {
  margin-top: 44px;
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  .office-building-wrapper {
    padding-top: 72px;
    padding-left: 0px;
  }
  .office-building-img{
    height: 164px;
    width: 280px;
    margin: 56px 0 0 !important;
  }
  .office-building-title {
    height: 72px;
    font-size: 28px;
    line-height: 36px;
    margin-top: 32px !important;
  }
  .office-building-description {
    width: 282px;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: 16px !important;
  }
}


@media screen and (min-width: 375px)  and (max-width: 767px) {
  .office-building-wrapper {
    max-width: 350px;
    margin: 0 auto;
    border-right: none;
    padding-top: 32px;
    padding-left: 0;
    padding-bottom: 48px;
  }
  .office-building-title {
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: 32px !important;
  }

  .office-building-description {
    width: 282px;
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: 20px !important;
  }
  .office-building-button {
    margin-top: 24px;
    margin-bottom: 48px;
  }

  .office-building-img{
    height: 180px;
    width: 343px;
    margin: 0 !important;
  }


}
@media screen and (max-width: 374px) {
  .office-building-wrapper {
    max-width: 350px;
    margin: 0 auto;
    border-right: none;
    padding-top: 32px;
    padding-left: 0;
    padding-bottom: 40px;
  }
  .office-building-title {
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: 32px !important;
  }

  .office-building-description {
    width: 282px;
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: 20px !important;
  }
  .office-building-button {
    margin-top: 24px;
    margin-bottom: 40px;
  }

  .office-building-img{
    height: 180px;
    width: 288px;
    margin: 0 !important;
  }
}
</style>
