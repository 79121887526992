<template>
  <b-modal id="calc-modal"
           body-bg-variant="coool"
           header-bg-variant="coool"
           header-class="header-class"
           @hidden="resetModal()"
           hide-footer
           size="lg"
           no-close-on-backdrop
           body-class="modal-class">
    <div class="close-btn" @click="$bvModal.hide('calc-modal')">
      <img src="../assets/icn_close.svg" class="cross" alt="X">
    </div>
    <Preloader v-if="is_loading"></Preloader>
    <div v-if="result_ok" class="sent-pad">
      <img src="../assets/img_thank_you.png" class="d-block mx-auto thank" alt="">
      <div class="d-block text-center">
        <p class="title">Thank you!</p>
        <div class="descr ">
          <p class="mb-0">Thank you for reaching out about RPIE compliance. </p>
          <p class="mb-0">We will be in touch with you shortly.</p>
        </div>
      </div>
      <button @click="$bvModal.hide('calc-modal')"  class="help-btn  w-100" >Done!</button>
    </div>
    <div v-else-if="result_no" class="sent-pad">
      <!--      <img src="../assets/error_outline.png" class="d-block mx-auto" alt="">-->
      <div class="d-block text-center p-3">
        <p class="title">Something went wrong</p>
        <div class="descr form-sent">
          <p class="mb-0">We are working on this problem now. </p>
          <p class="mb-0">Please try again or contact us: <b>718-522-1111</b></p>
        </div>
        <button @click="$bvModal.hide('calc-modal')"  class="help-btn  w-100" >Try again</button>
      </div>
    </div>
    <div v-else class="form-wrap">
      <div class="d-flex justify-content-center">
        <div>
          <p class="title text-center">
            Answer a Few Simple Questions
            to See if Your Project is Eligible</p>
          <p class="help-text">
            If your construction involves multiple tax lots,
            enter any one lot.
          </p>
        </div>

      </div>
      <div class="wrap-inputs container-fluid">
        <div class="row ">
          <div class="col-md-6 col-sm-12  pr-md-2 mb-sm">
            <b-form-select
                id="borough"
                class="cust-inp custom-select-bg"
                :class="borough_state === false ? 'err-state' : ''"
                v-model="borough"
                :state="borough_state"
                placeholder="Select borough"
            >
              <b-form-select-option :value="null">Select Borough</b-form-select-option>
              <b-form-select-option :value="1">Manhattan</b-form-select-option>
              <b-form-select-option :value="2">Bronx</b-form-select-option>
              <b-form-select-option :value="3">Brooklyn</b-form-select-option>
              <b-form-select-option :value="4">Queens</b-form-select-option>
              <b-form-select-option :value="5">Staten Island</b-form-select-option>
            </b-form-select>
<!--            <div v-if="borough_state  === false" >-->
<!--              <p v-if="!borough || borough.length === 0"  class="err-state-text m-0">-->
<!--                Required-->
<!--              </p>-->
<!--              <p v-else class="err-state-text m-0">-->
<!--                First name is incorrect-->
<!--              </p>-->
<!--            </div>-->
          </div>
          <div class="col-md-3 col-sm-12  pr-md-2 pl-md-2 mb-sm">
            <b-form-input
                id="borough"
                class="cust-inp"
                :class="block_state === false ? 'err-state' : ''"
                v-model="block"
                :formatter="formatter"
                :state="block_state"
                placeholder="Enter block"
            ></b-form-input>
<!--            <div v-if="block_state  === false" >-->
<!--              <p v-if="!block || block.length === 0"  class="err-state-text m-0">-->
<!--                Required-->
<!--              </p>-->
<!--              <p v-else class="err-state-text m-0">-->
<!--                Block is incorrect-->
<!--              </p>-->
<!--            </div>-->
          </div>
          <div class="col-md-3 col-sm-12  pl-md-2 mb-sm">
            <b-form-input
                id="lot"
                :formatter="formatter"
                class="cust-inp"
                :class="lot_state === false ? 'err-state' : ''"
                v-model="lot"
                :state="lot_state"
                placeholder="Enter lot"
            ></b-form-input>
<!--            <div v-if="lot_state  === false" >-->
<!--              <p v-if="!lot || lot.length === 0"  class="err-state-text m-0">-->
<!--                Required-->
<!--              </p>-->
<!--              <p v-else class="err-state-text m-0">-->
<!--                Lot is incorrect-->
<!--              </p>-->
<!--            </div>-->
          </div>
        </div>
        <div class="row selects-block">
          <div class="col-md-6 col-12  pr-md-2 mb-sm">
            <b-form-select
                id="borough"
                class="cust-inp custom-select-bg"
                :class="borough_state === false ? 'err-state' : ''"
                v-model="constr_cost"
                :state="constr_cost_state"
                placeholder="Select borough"
            >
              <b-form-select-option :value="null">Select Construction Cost</b-form-select-option>
              <b-form-select-option value="Under $50,000">Under $50,000</b-form-select-option>
              <b-form-select-option value="$50,000 - $749,999">$50,000 - $749,999</b-form-select-option>
              <b-form-select-option value="$750,000 - $1.49 MM">$750,000 - $1.49 MM</b-form-select-option>
              <b-form-select-option value="$1.5 MM - $2.49 MM">$1.5 MM - $2.49 MM</b-form-select-option>
              <b-form-select-option value="$2.5 MM or more">$2.5 MM or more</b-form-select-option>
            </b-form-select>
<!--            <div v-if="borough_state  === false" >-->
<!--              <p v-if="!borough || borough.length === 0"  class="err-state-text m-0">-->
<!--                Required-->
<!--              </p>-->
<!--              <p v-else class="err-state-text m-0">-->
<!--                First name is incorrect-->
<!--              </p>-->
<!--            </div>-->
          </div>
          <div class="col-md-6 col-12 pl-md-2">
            <b-form-select
                id="borough"
                class="cust-inp custom-select-bg"
                :class="borough_state === false ? 'err-state' : ''"
                v-model="constr_type"
                :state="constr_type_state"
                placeholder="Select borough"
            >
              <b-form-select-option :value="null">Select Construction Type</b-form-select-option>
              <b-form-select-option value="New Construction">New Construction</b-form-select-option>
              <b-form-select-option value="Alteration/Renovation">Alteration/Renovation</b-form-select-option>
            </b-form-select>
<!--            <div v-if="borough_state  === false" >-->
<!--              <p v-if="!borough || borough.length === 0"  class="err-state-text m-0">-->
<!--                Required-->
<!--              </p>-->
<!--              <p v-else class="err-state-text m-0">-->
<!--                First name is incorrect-->
<!--              </p>-->
<!--            </div>-->
          </div>
        </div>
      </div>
      <div class="" >
        <div v-if="result_ok || result_no" class="lic-less d-flex justify-content-center hand">
          <button @click="$bvModal.hide('calc-modal')"  class="help-btn  w-100" >Close</button>
        </div>
        <div v-else class="">
          <button @click="Calc()" :class="disabled_send ? 'not-allowed' : 'hand'"  class="help-btn  w-100" >Next step</button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Preloader from '../components/Preloader.vue'
import axios from "axios";
export default {
name: "CalcModal",
  components: {
    Preloader,
  },
  data: () => ({
    result_ok : false,
    result_no : false,
    is_loading : false,
    disabled_send : false,

    borough: null,
    constr_cost: null,
    constr_type: null,
    lot: null,
    block: null,

    borough_state: null,
    constr_type_state: null,
    constr_cost_state: null,
    lot_state : null,
    block_state : null,
  }),
  watch:{
    borough(){
      this.borough_state = null
    },
    constr_cost(){
      this.constr_cost_state = null
    },
    constr_type(){
      this.constr_type_state = null
    },
    lot(){
      this.lot_state = null
    },
    block(){
      this.block_state = null
    },
  },
  methods:{
    formatter(val){
      return val.replace(/[^0-9.]/g, '')
    },
   async Calc(){
      if(this.validate() && !this.disabled_send){
        this.makeDisabled()
        this.is_loading = true;
        await axios.post('https://mgny.force.com/icap/services/apexrest/checkEligibility',
            {
              'boroughCode': this.borough,
              'block': this.block,
              'lot': this.lot,
              'projectCost': this.constr_cost,
              'constructionType': this.constr_type,

            })
            .then((res)=>{
              if((res && res.data && res.data.eligible === false) || (res && res.data && res.data.eligible === true)){
                let params = {
                  result : res.data.eligible,
                  BBL : this.borough + ' ' + this.block + ' ' + this.lot,
                  ConstructionCost : this.constr_cost,
                  ConstructionType : this.constr_type,
                }
                this.$bvModal.hide('calc-modal')
                this.$emit('sent', params )
                this.resetModal()
              }
              this.sent_success = true
              this.is_loading = false;
            }).catch((error)=>{
              this.result_no = true;
              this.is_loading = false;
              console.log('Error' + error)
            })
      }
    },
    resetModal(){
      this.borough_state = null;
      this.constr_cost_state = null;
      this.constr_type_state = null;
      this.block_state = null;
      this.lot_state = null;

      this.borough = null;
      this.constr_cost = null;
      this.constr_type = null;
      this.lot = null;
      this.block= null;
    },
    makeDisabled(){
      this.disabled_send = true;
      setTimeout(() => this.disabled_send = false, 1000)
    },
    validate(){
      this.borough_state = null;
      this.constr_cost_state = null;
      this.constr_type_state = null;
      this.block_state = null;
      this.lot_state = null;
      let is_valid = true

      if(this.borough === null){
        this.borough_state = false;
        is_valid = false
      }
      if(this.constr_cost === null){
        this.constr_cost_state = false;
        is_valid = false
      }
      if(this.constr_type === null){
        this.constr_type_state = false;
        is_valid = false
      }
      if(this.block === null || this.block === ''){
        this.block_state = false;
        is_valid = false
      }
      if(this.lot === null || this.lot === ''){
        this.lot_state = false;
        is_valid = false
      }
      return is_valid
   }

  }
}
</script>
<style lang="scss">
.err-state{
  background-color: #FFE9E8!important;
  border: 1px solid #FF7564!important;
&::placeholder{
   color: #FF7564!important;
   font-family: "Open Sans";
 }
}
.custom-select-bg{
  background: #F5F5F7 url("../assets/assets_icap_web/icn_dropdown.svg") no-repeat right 16px center/12px 10px!important;
}
.modal-backdrop{
  transition: 0.8s;
  opacity: 0.88!important;
  background-color: rgba(1,2,15,1)!important
}
#calc-modal___BV_modal_header_{
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .close-btn{
    display: none;
  }
  #calc-modal___BV_modal_header_{
    display: block;
  }
  .modal-dialog {
    max-width: 640px!important;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .close-btn{
    display: none;
  }
  #calc-modal___BV_modal_header_{
    display: block;
  }
}
</style>
<style lang="scss" scoped>
.form-wrap{
  padding: 16px;
}
.wrap-inputs{
  margin-bottom: 32px;
}
.cust-inp{
  height: 48px;
  border-radius: 6px;
  border-color: transparent;
  background-color: #F5F5F7;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  color: #0A1250;
&::placeholder{
   color: #7F83A7;
   font-family: "Open Sans";
   font-size: 15px;
   letter-spacing: 0;
   line-height: 20px;
 }
&:active{
   box-sizing: border-box;
   height: 48px;
   border: 1px solid #4D59EB;
   border-radius: 6px;
 }
&:focus{
   box-shadow: none;
   border: 1px solid #4D59EB;
 }
}
.title{
  width: 356px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  margin: 0 auto 12px;
}
.help-text{
  width: 321px;
  color: #7F83A7;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin: auto;
}
.descr{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-bottom: 87px;
}
.close-btn{
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #4b4d56;
  box-shadow: 0 2px 4px 0 rgba(26, 41, 60, 0.15);
  position: absolute;
  top: 0;
  right: -300px;
  cursor: pointer;
  z-index: 999;
  padding: 8px 13px;
}
.cross{
  height: 15px;
  width: 15px;
  color: #FFFFFF;
}
.selects-block{
  margin-top: 32px;
}

@media screen and (min-width: 768px) and (max-width: 1223px) {
  .wrap-inputs{
    padding: 0;
    width: 100% !important;
    margin-top: 40px;
  }
  .title {
    margin: -19px 0 12px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .mb-sm{
    margin-bottom: 24px;
  }
  .selects-block{
    margin-top: 0;
  }
  .form-wrap[data-v-95e9cee4] {
    padding: 0
  }
  .title{
    margin : -30px 0 2px;
    font-size: 24px;
    line-height: 40px;
    width: 303px;
  }
  .help-text{
    width: 288px;
  }
  .wrap-inputs{
    padding: 0;
    width: 100% !important;
    margin-top: 25px;
    margin-bottom: 24px;
  }
  .mb-32px{
    margin-bottom: 28px;
  }
  .sent-pad{
    padding: 0 0px 38px;
  }
  .descr{
    margin-bottom: 60px;
  }
}
</style>