<template>
  <div class="wrap">
    <div>
      <p class="icap-exec">
        <span class="font-bold">MGNY Consulting</span> has lead the ICAP execution
        for dozens of projects and has been proudly
        serving the NYC developers for the past 12 years
      </p>
      <div class="text-center">
        <HelpButton :text="'Check if Your Project is Eligible'"></HelpButton>
      </div>
    </div>
  </div>
</template>

<script>
import HelpButton from '@/components/HelpButton'
export default {
name: "IcapExec",
  components:{
    HelpButton
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
  .wrap{
    height: 612px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.font-bold{
  font-weight: 600;
}
.icap-exec{
  width: 829px;
  color: $font-black;
  font-family: "Open Sans";
  font-size: 38px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 56px;
  text-align: center;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) and (max-width: 1439px) {
  .wrap{
    height: 392px;
  }
  .icap-exec{
    width: 611px;
    font-size: 28px;
    line-height: 40px;
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .wrap{
    height: 566px;
  }
  .icap-exec{
    font-size: 24px;
    line-height: 34px;
    width: 347px;
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .wrap{
    height: 364px;
  }
  .icap-exec{
    font-size: 20px;
    line-height: 32px;
    width: 289px;
  }
}
</style>
