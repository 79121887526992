<template>
  <div class="cases-studies-block-bg">
    <div class="border-bottom-2 border-bottom-sm-2 ">
      <div class="border-bottom-sm-2">
        <div class="container-width">
          <div class="row">
            <div class="col-12 cases-studies_wrapper">
              <h3 class="cases-studies_title text-center mb-0">Case Studies</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="container-width">
        <PrivateSchool></PrivateSchool>
      </div>
    </div>
    <div class="border-bottom-2 ">
      <div class="container-width">
        <div class="row">
          <div class="col-md-6 col-12 office-building-wrapper-border">
            <OfficeBuilding></OfficeBuilding>
          </div>
          <div class="col-md-6 col-12 ">
            <MixedUseCondominimum></MixedUseCondominimum>
          </div>
        </div>
      </div>
    </div>
    <div class="container-width">
      <SportsCompex></SportsCompex>
    </div>
  </div>
</template>

<script>
import PrivateSchool from "../components/PrivateSchool";
import SportsCompex from "../components/SportsCompex";
import OfficeBuilding from "../components/OfficeBuilding";
import MixedUseCondominimum from "../components/MixedUseCondominimum";
export default {
  name: "CaseStudies",
  components: {
    PrivateSchool,
    SportsCompex,
    OfficeBuilding,
    MixedUseCondominimum,
  }
}
</script>

<style scoped>
.cases-studies-block-bg {
  background-color: rgba(255, 243, 222, 0.4);
}
.cases-studies_title {
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 48px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 52px;
}
.cases-studies_wrapper {
  padding: 100px 0;
}
.border-bottom-2 {
  border-bottom: 2px solid #F7E7D9;
}
.office-building-wrapper-border {
  border-right: 2px solid #F7E7D9;
}
@media screen and (min-width: 768px) and (max-width: 1439px) {
  .cases-studies_wrapper {
    padding: 72px 0;
  }
  .cases-studies_title {
    font-size: 36px;
    font-weight: 300;
    letter-spacing: -0.69px;
    line-height: 46px;
  }
  .border-bottom-2 > div {
    max-width: 640px;
    margin: 0 auto;
  }
  .border-bottom-2 .col-6 {
    padding: 0;
  }
}

@media screen and (min-width: 376px) and (max-width: 767px) {

  .cases-studies_wrapper {
    padding: 48px 0;
  }
  .cases-studies_title {
    font-size: 32px;
    letter-spacing: -0.62px;
    line-height: 40px;
  }

  .office-building-wrapper-border {
    border-right: none;
    border-bottom: 2px solid #F7E7D9;
  }
}

@media screen and (max-width: 375px) {
  .cases-studies_wrapper {
    padding: 36px 0;
  }
  .cases-studies_title {
    font-size: 28px;
    letter-spacing: -0.54px;
    line-height: 40px;
  }

  .office-building-wrapper-border {
    border-right: none;
  }

  .office-building-wrapper-border {
    border-bottom: 2px solid #F7E7D9;
  }
  .border-bottom-sm-2 {
    border-bottom: 2px solid #F7E7D9;
  }
}
</style>


