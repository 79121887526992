<template>
  <div id="app ">
    <Header></Header>
    <HeroPage  class="bg-color-white"></HeroPage>
    <div  class="container-width">
    </div>
    <IcapExec></IcapExec>
    <DontMiss></DontMiss>
    <CaseStudies></CaseStudies>
    <Footer></Footer>
    <SignUpModal ref="sign_up"></SignUpModal>
    <CalcModal @sent="sent"></CalcModal>
  </div>
</template>

<script>
import Header from '@/views/Header'
import HeroPage from '@/views/HeroPage'
import IcapExec from '@/views/IcapExec'
import DontMiss from '@/views/DontMiss'
import Footer from '@/components/Footer'
import CaseStudies from "./views/CaseStudies";
import SignUpModal from '@/views/SignUpModal'
import CalcModal from '@/views/CalcModal'

import VueMeta from 'vue-meta'
import Vue from 'vue'
Vue.use(VueMeta)


export default {
  name: 'App',
  metaInfo() {
    return {
      meta: [
        // { name: 'facebook-domain-verification', content:  't1bwny25njn2g63h2p57mnewb826gr'},
      ]
    }
  },

  components: {
    HeroPage,
    Footer,
    Header,
    CaseStudies,
    IcapExec,
    DontMiss,
    SignUpModal,
    CalcModal
  },
  methods:{
    sent(params){
      this.$refs.sign_up.startModal(params)
    }
  }
}
</script>

<style lang="scss">
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

.container-width{
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.bg-color-white {
  background-color: rgb(255, 255, 255);
}
//@media  (min-width:1520px)  {
//  .container-width {
//    width: 1420px;
//  }
//}
@media  (min-width:1441px)  {
  .container-width {
    width: 1440px;
  }
}
@media  (max-width:1439px) and (min-width:1024px)  {
  .container-width {
    width: 100%;
  }
}
@media  (max-width:1023px) and (min-width:760px)  {
  .container-width {
    width: 100%;
    padding: 0 2px;
  }
}
@media  (max-width:760px) and (min-width:320px)  {
  .container-width {
    width: 100%;
    padding: 0 16px;
  }
}


@font-face {
  font-family: "Integral CF ExtraBold";
  src: local("Integral CF"),   url('./fonts/IntegralCF-ExtraBold.woff2')  format("woff2");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Open Sans";
  src:   url('./fonts/OpenSans-Regular.ttf')   format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Open Sans";
  src:   url('./fonts/OpenSans-SemiBold.ttf')   format('truetype');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Open Sans";
  src:   url('./fonts/OpenSans-Bold.ttf')   format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Open Sans";
  src:   url('./fonts/OpenSans-Light.ttf')   format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Open Sans-ExtraBold";
  src:   url('./fonts/OpenSans-ExtraBold.ttf')   format('truetype');
  font-style: normal;
  font-weight: 800;
}

$grid-breakpoints: (
  xs: 0,
  sm: 376px,
  md: 768px,
  lg: 1024px,
  xl: 1440px,
  xxl: 1920px
);

$container-max-widths: (
sm: 374px,
md: 732px,
lg: 976px,
xl: 1380px,
xxl: 1500px
);

// Import BT sources
@import "../node_modules/bootstrap/scss/bootstrap";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
